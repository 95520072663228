


























































































import { OperationInput } from '@/models/operation.model';
import { defineComponent, inject, Ref, ref } from '@vue/composition-api';

import { VForm } from '@/types/form.type';
import { Emitter } from 'mitt';
import { Message, MessageType, MessageEvent } from '@/types/emitter.type';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { CREATE_OR_SAVE_CAR, GET_CARS, REMOVE_CARS } from '@/store/car.actions';
import {
  CREATE_OR_SAVE_ORGANISATION,
  GET_ORGANISATIONS,
  REMOVE_ORGANISATIONS
} from '@/store/organisation.actions';
import {
  CREATE_OR_SAVE_OPERATION,
    REMOVE_OPERATIONS,
  UPLOAD_IMAGE
} from '@/store/operation.actions';
import { CarEntry, ParsedCarEntry } from '@/models/car.model';
import {
  OrganisationEntry,
  ParsedOrganisationEntry
} from '@/models/organisation.model';
import { Entry } from '@/models/entry.model';
import { wait } from '@/helpers/wait';

import { isValid, parse } from 'date-fns';
import * as JSZip from 'jszip';

interface Dict {
  [key: string]: string;
}

interface ParsedOperationDict {
  [key: string]: Dict;
}

interface Dataset {
  type: 'header' | 'database' | 'table';
  data: Dict[];
}

interface DictArray {
  [key: string]: string[];
}

interface ParsedOperation {
  id: string;
  data1: string;
  address: string;
  date1: string;
  summary: string;
  boss: string;
  people: string;
  department: string;
  desc: string;
  presse: string;
}

export default defineComponent({
  name: 'UploadBackup',
  // props: {
  // },
  components: {},
  setup: () => {
    const valid = ref(false);
    const form = ref();

    const jsonReportFile: Ref<File | null> = ref(null);
    const jsonVehiclesFile: Ref<File | null> = ref(null);
    const jsonDepsFile: Ref<File | null> = ref(null);
    const jsonVehiclesLinkFile: Ref<File | null> = ref(null);
    const jsonDepsLinkFile: Ref<File | null> = ref(null);
    const jsonImgLinkFile: Ref<File | null> = ref(null);
    const imgZipFile: Ref<File | null> = ref(null);

    let backupData: ParsedOperationDict = {};
    let vehicleData = {};
    let depsData = {};
    const imgData: { [key: string]: Blob } = {};

    let carLinkData = {} as DictArray;
    let depsLinkData = {} as DictArray;
    let imgLinkData = {} as DictArray;

    const $emitter = inject('$emitter') as Emitter;

    let error = false;

    const extractTableData = (datasets: Dataset[]) => {
      return datasets
        .find(dataset => dataset.type === 'table')
        ?.data.reduce((dict, entry) => {
          dict[entry.id] = entry;
          return dict;
        }, {} as { [key: string]: Dict });
    };

    const extractLinkTableData = (datasets: Dataset[], linkKey: string) => {
      return datasets
        .find(dataset => dataset.type === 'table')
        ?.data.reduce((dict, entry) => {
          if (!dict[entry.report_id]) {
            dict[entry.report_id] = [] as string[];
          }
          dict[entry.report_id].push(entry[linkKey]);
          return dict;
        }, {} as DictArray);
    };

    const changeReportFile = () => {
      if (!jsonReportFile.value) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsText(jsonReportFile.value);
      fileReader.onloadend = () => {
        const allLines = fileReader.result as string;
        const datasets = JSON.parse(allLines);
        backupData = extractTableData(datasets) ?? {};
      };
      fileReader.onerror = () => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Einsatz Datei konnte nicht gelesen werden'
        };

        $emitter?.emit(MessageEvent.Global, msg);
        error = true;
      };
    };

    const changeVehiclesFile = () => {
      if (!jsonVehiclesFile.value) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsText(jsonVehiclesFile.value);
      fileReader.onloadend = () => {
        const allLines = fileReader.result as string;
        const datasets = JSON.parse(allLines);
        vehicleData = extractTableData(datasets) ?? {};
      };
      fileReader.onerror = () => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Fahrzeug Datei konnte nicht gelesen werden'
        };

        $emitter?.emit(MessageEvent.Global, msg);
        error = true;
      };
    };

    const changeDepsFile = () => {
      if (!jsonDepsFile.value) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsText(jsonDepsFile.value);
      fileReader.onloadend = () => {
        const allLines = fileReader.result as string;
        const datasets = JSON.parse(allLines);
        depsData = extractTableData(datasets) ?? {};
      };
      fileReader.onerror = () => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Einheit Datei konnte nicht gelesen werden'
        };

        $emitter?.emit(MessageEvent.Global, msg);
        error = true;
      };
    };

    const changeVehiclesLinkFile = () => {
      if (!jsonVehiclesLinkFile.value) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsText(jsonVehiclesLinkFile.value);
      fileReader.onloadend = () => {
        const allLines = fileReader.result as string;
        const datasets = JSON.parse(allLines);
        carLinkData = extractLinkTableData(datasets, 'vehicle_id') ?? {};
      };
      fileReader.onerror = () => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Fahrzeug Link Datei konnte nicht gelesen werden'
        };

        $emitter?.emit(MessageEvent.Global, msg);
        error = true;
      };
    };

    const changeDepsLinkFile = () => {
      if (!jsonDepsLinkFile.value) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsText(jsonDepsLinkFile.value);
      fileReader.onloadend = () => {
        const allLines = fileReader.result as string;
        const datasets = JSON.parse(allLines);
        depsLinkData = extractLinkTableData(datasets, 'department_id') ?? {};
      };
      fileReader.onerror = () => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Einheit Link Datei konnte nicht gelesen werden'
        };

        $emitter?.emit(MessageEvent.Global, msg);
        error = true;
      };
    };

    const changeImgLinkFile = () => {
      if (!jsonImgLinkFile.value) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsText(jsonImgLinkFile.value);
      fileReader.onloadend = () => {
        const allLines = fileReader.result as string;
        const datasets = JSON.parse(allLines);
        imgLinkData = extractLinkTableData(datasets, 'image') ?? {};
      };
      fileReader.onerror = () => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Bild Link Datei konnte nicht gelesen werden'
        };

        $emitter?.emit(MessageEvent.Global, msg);
        error = true;
      };
    };

    const showProgress = ref(false);
    const disableUploadButton = ref(false);
    const changeImgZipFile = () => {
      if (!imgZipFile.value) {
        return;
      }

      let curr = 0;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(imgZipFile.value);
      fileReader.onloadend = () => {
        JSZip.loadAsync(fileReader.result as ArrayBuffer).then(zip => {
          zip.forEach((relativePath: string, file: JSZip.JSZipObject) => {
            file.async('blob').then(blob => {
              const filename = file.name;
              imgData[filename] = new File([blob], filename);
              disableUploadButton.value =
                ++curr < Object.keys(zip.files).length;

              showProgress.value = disableUploadButton.value;
            });
          });
        });
      };
      fileReader.onerror = () => {
        const msg: Message = {
          type: MessageType.Error,
          text: 'Bild Zip Datei konnte nicht gelesen werden'
        };

        $emitter?.emit(MessageEvent.Global, msg);
        error = true;
      };
    };

    type Func = <T extends Entry>(saveEntries: T[]) => Promise<T[]>;
    const performBatchSave = async <T extends Entry>(
      saveEntries: T[],
      createSaveAction: Func
    ) => {
      const entryIds = {} as Dict;
      const entriesCount = saveEntries.length;
      for (let i = 0; i < entriesCount; ) {
        // sliding window
        const currSaveEntries = [] as T[];
        for (let j = 0; j < 10 && i < entriesCount; ++i, ++j) {
          currSaveEntries.push(saveEntries[i]);
        }

        // save to db
        const currentSavedEntries = (await createSaveAction(
          currSaveEntries.map(c => ({ name: c.name }))
        )) as T[];
        currentSavedEntries.forEach((_: T, idx: number) => {
          if (
            currSaveEntries[idx]._id != null &&
            currentSavedEntries[idx]._id != null
          ) {
            entryIds[currSaveEntries[idx]._id as string] = currentSavedEntries[idx]._id as string;
          }
        });
        await wait(100);
      }

      return entryIds;
    };

    type OpFunc = (saveEntries: OperationInput[]) => Promise<OperationInput[]>;
    const performOpBatchSave = async (
      saveEntries: OperationInput[],
      createSaveAction: OpFunc
    ) => {
      const entryIds = {} as Dict;
      const entriesCount = saveEntries.length;
      for (let i = 0; i < entriesCount; ) {
        const currSaveEntries = [];
        for (let j = 0; j < 20 && i < entriesCount; ++i, ++j) {
          currSaveEntries.push(saveEntries[i]);
        }
        await createSaveAction(currSaveEntries);

        await wait(750);
      }

      return entryIds;
    };

    const getters = useGetters([GET_CARS, GET_ORGANISATIONS]);
    const actions = useActions([
      CREATE_OR_SAVE_OPERATION,
      CREATE_OR_SAVE_CAR,
      CREATE_OR_SAVE_ORGANISATION,
      UPLOAD_IMAGE,

      REMOVE_CARS,
      REMOVE_ORGANISATIONS,
      REMOVE_OPERATIONS
    ]);
    const restoreBackup = async () => {
      const vform = form?.value as VForm;
      if (!vform?.validate() || error) {
        return;
      }

      showProgress.value = true;

      // remove old data
      const removeCarsAction = actions[REMOVE_CARS];
      const removeOrganisationsAction = actions[REMOVE_ORGANISATIONS];
      const removeOperationsAction = actions[REMOVE_OPERATIONS];
      await removeCarsAction();
      await wait(500);

      await removeOrganisationsAction();
      await wait(500);

      await removeOperationsAction();
      await wait(500);

      // add cars
      const cars = getters[GET_CARS];
      const createSaveCarAction = actions[CREATE_OR_SAVE_CAR];
      const saveCars = Object.entries(vehicleData)
        .filter(async ([id, _car]) => {
          const car = _car as ParsedCarEntry;
          return !(cars.value as CarEntry[]).some(c => c?.name === car.name);
        })
        .map(([id, car]) => ({
          _id: (car as ParsedCarEntry).id,
          name: (car as ParsedCarEntry).name
        }));

      const carIds = await performBatchSave(
        saveCars,
        async (saveEntries: CarEntry[]) => createSaveCarAction(saveEntries)
      );

      // add organisations
      const organisations = getters[GET_ORGANISATIONS];
      const createSaveOrganisationAction = actions[CREATE_OR_SAVE_ORGANISATION];
      const saveOrganisations = Object.entries(depsData)
        .filter(async ([id, _organisation]) => {
          const organisation = _organisation as ParsedOrganisationEntry;
          return !(organisations.value as OrganisationEntry[]).some(
            o => o?.name === organisation.name
          );
        })
        .map(([id, organisation]) => ({
          _id: (organisation as ParsedOrganisationEntry).id,
          name: (organisation as ParsedOrganisationEntry).name
        }));

      const orgIds = await performBatchSave(
        saveOrganisations,
        async (saveEntries: OrganisationEntry[]) =>
          createSaveOrganisationAction(saveEntries)
      );

      // add images and reports
      const uploadImageAction = actions[UPLOAD_IMAGE];
      const createSaveOpAction = actions[CREATE_OR_SAVE_OPERATION];

      const uploadOperations = [] as OperationInput[];
      for (const id in backupData) {
        const opImgs = imgLinkData[id]?.map(filename => imgData[filename]);
        const uploadImageIds =
          opImgs?.length > 0 ? await uploadImageAction(opImgs) : [];

        await wait(750);

        const _operation = backupData[id] as Dict;
        const date = parse(_operation.date1, 'yyyy-MM-dd HH:mm:ss', new Date());
        const uploadOp = {
          // _id: _operation.id,
          zeitstempel: isValid(date) ? date.toISOString() : null,
          einsatzart: _operation.data1,
          kurzbericht: _operation.summary,
          fahrzeuge: carLinkData[id]?.map(c => ({
            _id: carIds[c],
            link: 'fahrzeug'
          })),
          einheiten: depsLinkData[id]?.map(o => ({
            _id: orgIds[o],
            link: 'einheit'
          })),
          bericht: _operation.desc,
          ort: _operation.address,
          presse: _operation.presse,
          bilder: uploadImageIds?.length > 0 ? uploadImageIds : null
        } as OperationInput;

        uploadOperations.push(uploadOp);
      }

      const res = await performOpBatchSave(
        uploadOperations,
        async (saveEntries: OperationInput[]) => createSaveOpAction(saveEntries)
      );
      if (res) {
        vform?.reset();
      }

      showProgress.value = false;
    };

    const inputRules = ref([(v: string) => !!v || 'Bitte SQL Datei angeben']);

    return {
      valid,
      form,
      jsonReportFile,
      jsonVehiclesFile,
      jsonDepsFile,
      jsonVehiclesLinkFile,
      jsonDepsLinkFile,
      jsonImgLinkFile,
      imgZipFile,
      changeReportFile,
      changeVehiclesFile,
      changeDepsFile,
      changeVehiclesLinkFile,
      changeDepsLinkFile,
      changeImgLinkFile,
      changeImgZipFile,
      restoreBackup,
      inputRules,
      showProgress,
      disableUploadButton
    };
  }
});
